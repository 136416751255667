import { Injectable } from '@angular/core';
import { createStore, select, setProp, withProps } from '@ngneat/elf';

import { Entity, EntityMemberRoleType, EntityTeam } from '@state/entities';

import {
  FeatureConfiguration,
  FeatureConfigurationListing,
  FeatureConfigurationOption,
  FeatureConfigurationType,
  FeatureRoleConfigurationActionType,
  MappedConfigurationOptions
} from './entity.model';

interface EntityProps {
  entity?: Entity;
  entityTeams?: EntityTeam[];
  featureConfigurations: FeatureConfiguration[];
}

export const availableFeatureConfigurations: FeatureConfigurationOption[] = [
  {
    actions: [FeatureRoleConfigurationActionType.Send],
    roles: [
      EntityMemberRoleType.Owner,
      EntityMemberRoleType.Administrator,
      EntityMemberRoleType.FundAdministrator,
      EntityMemberRoleType.LegalTeam,
      EntityMemberRoleType.Limited
    ],
    type: FeatureConfigurationType.QuestionnaireReview
  },
  {
    actions: [FeatureRoleConfigurationActionType.Submit, FeatureRoleConfigurationActionType.Approve],
    roles: [
      EntityMemberRoleType.Owner,
      EntityMemberRoleType.Administrator,
      EntityMemberRoleType.FundAdministrator,
      EntityMemberRoleType.LegalTeam,
      EntityMemberRoleType.Limited
    ],
    type: FeatureConfigurationType.CommentReview
  }
];

const initialState: EntityProps = {
  entity: undefined,
  entityTeams: undefined,
  featureConfigurations: []
};

@Injectable({ providedIn: 'root' })
export class EntityRepository {
  private _store$ = createStore({ name: 'entity' }, withProps<EntityProps>(initialState));

  entity$ = this._store$.pipe(select(({ entity }) => entity));
  teams$ = this._store$.pipe(select(({ entityTeams }) => entityTeams));

  clearTeams(): void {
    this.setTeams();
  }

  getEntity(): Entity {
    return this._store$.getValue().entity;
  }

  getFeatureConfigurationListings(): FeatureConfigurationListing[] {
    let featureConfigurations = this._store$.getValue().featureConfigurations;

    return availableFeatureConfigurations.map(
      (availableFeatureConfiguration) =>
        featureConfigurations.find(
          (featureConfiguration) => featureConfiguration.type === availableFeatureConfiguration.type
        ) || { enabled: false, role_configurations: [], type: availableFeatureConfiguration.type }
    );
  }

  getFeatureConfigurationsModel(): MappedConfigurationOptions {
    let featureConfigurations = this._store$.getValue().featureConfigurations;
    let mappedConfigurationOptions: MappedConfigurationOptions = new Map();

    // Iterates over hardcoded configurations to give use available options for mapping
    for (let featureConfigurationOption of availableFeatureConfigurations) {
      let { actions, roles, type } = featureConfigurationOption;

      mappedConfigurationOptions[type] = new Map();

      for (let role of roles) {
        mappedConfigurationOptions[type][role] = new Map();

        for (let action of actions) {
          if (role === EntityMemberRoleType.Owner) {
            mappedConfigurationOptions[type][role][action] = true;
          } else {
            mappedConfigurationOptions[type][role][action] = false;
          }
        }
      }
    }

    // Iterates over configurations recieved from the server to reflect options granted
    for (let featureConfig of featureConfigurations) {
      for (let roleConfig of featureConfig.role_configurations) {
        // guard against deprecated roles.
        if (mappedConfigurationOptions[featureConfig.type][roleConfig.role] === undefined) continue;

        for (let action of roleConfig.actions) {
          mappedConfigurationOptions[featureConfig.type][roleConfig.role][action] = true;
        }
      }
    }

    return mappedConfigurationOptions;
  }

  getFeatureConfigurations(): FeatureConfiguration[] {
    return this._store$.getValue().featureConfigurations;
  }

  getFeatureConfigurationsByType(): Record<string, FeatureConfiguration> {
    let featureConfigurations = this.getFeatureConfigurations();

    return featureConfigurations.reduce((result, currentFeature) => {
      result[currentFeature.type] = currentFeature;
      return result;
    }, {});
  }

  reset(): void {
    this._store$.reset();
  }

  setEntity(entity: Entity): void {
    this._store$.update(setProp('entity', entity));
  }

  setFeatureConfigurations(featureConfigurations: FeatureConfiguration[]): void {
    this._store$.update(setProp('featureConfigurations', featureConfigurations));
  }

  setTeams(entityTeams?: EntityTeam[]): void {
    this._store$.update(setProp('entityTeams', entityTeams));
  }

  updateFeatureConfiguration(newFeatureConfiguration: FeatureConfiguration): void {
    this._store$.update((state) => {
      let featureConfigurations = state?.featureConfigurations ?? [];
      let featureExists = featureConfigurations.some(
        (feature) => feature.type === newFeatureConfiguration.type
      );

      let updatedFeatureConfigurations: FeatureConfiguration[];

      if (featureExists) {
        updatedFeatureConfigurations = featureConfigurations.map((feature) =>
          feature.type === newFeatureConfiguration.type ? newFeatureConfiguration : feature
        );
      } else {
        updatedFeatureConfigurations = [...featureConfigurations, newFeatureConfiguration];
      }

      return {
        ...state,
        featureConfigurations: updatedFeatureConfigurations
      };
    });
  }
}
