import { createStore, select, setProp, withProps } from '@ngneat/elf';
import { Injectable } from '@angular/core';

import { ColumnType, MetaValues, TooltipValues } from '@component/collection-table';
import { FeatureConfigurationType, FeatureConfigurationUserPermissions } from '@state/entity';
import { formatCurrency } from '@helpers/number';
import { ID } from '@state/base';
import { Investor } from '@state/investor';
import { OverviewCard } from '@component/overview-cards';
import { parseDate } from '@helpers/date';
import { truncate } from '@helpers/string';

import {
  Details,
  Fund,
  FundBankAccount,
  FundNotification,
  Investor as FundInvestor,
  InvestorQuestionnaireData,
  InvestorQuestionnaireReview,
  InvestorQuestionnaireReviewActivityLog,
  LegalData,
  LegalRow,
  LegalSection,
  LegalSectionData,
  LegalStatus,
  PropertyType,
  Summary,
  QuestionnaireReviewActivity
} from './fund.model';

interface FundProps {
  activeLegalStatusTags?: LegalStatus[];
  bankAccount?: FundBankAccount;
  details?: Details;
  featureConfigurationUserPermissions: Record<string, FeatureConfigurationUserPermissions>;
  fund?: Fund;
  investorQuestionnaireData?: InvestorQuestionnaireData[];
  investorQuestionnaireReview?: InvestorQuestionnaireReview;
  investorQuestionnaireReviewActivityLog?: InvestorQuestionnaireReviewActivityLog;
  investors?: FundInvestor[];
  legalData?: LegalData;
  legalSections?: LegalSection[];
  notifications?: FundNotification[];
  overviewInvestments?: OverviewCard[][];
  selectedLegalSection?: LegalSection;
  summary?: Summary;
}

const initialState: FundProps = {
  activeLegalStatusTags: undefined,
  bankAccount: undefined,
  details: undefined,
  featureConfigurationUserPermissions: {},
  fund: undefined,
  investorQuestionnaireReview: undefined,
  investorQuestionnaireReviewActivityLog: undefined,
  investors: undefined,
  legalData: undefined,
  legalSections: undefined,
  notifications: undefined,
  overviewInvestments: undefined,
  selectedLegalSection: undefined,
  summary: undefined
};

const MAX_LENGTH = 23;

let getCollectionTableColumnType = (type: PropertyType): ColumnType => {
  switch (type) {
    case PropertyType.Date:
      return ColumnType.Date;
    case PropertyType.String:
      return ColumnType.Tooltip;
    default:
      return undefined;
  }
};

let getTooltip = (value?: string): string => {
  if ((value?.length ?? 0) > MAX_LENGTH) return value;
};

export const getColumnValue = (
  key: string,
  columnType: ColumnType,
  metaKey?: string
): string | TooltipValues | MetaValues => {
  switch (columnType) {
    case ColumnType.Tooltip:
      return {
        main: (row: { [key: string]: string }) => truncate(row[key]),
        tooltip: (row: { [key: string]: string }) => getTooltip(row[key])
      };
    case ColumnType.Meta:
      return {
        main: (row: { [key: string]: string }) => row[key],
        meta: (row: { [key: string]: string }) => row[metaKey] ?? ''
      };
    default:
      return key;
  }
};

// Does the actual work for transforming legal sections, selector is below within class
let transformLegalSection = (state: FundProps): LegalSectionData => {
  let { legalData } = state;
  if (!legalData) return;

  let currencyColumnKeys: string[] = [];

  let columns = legalData?.columns.map((column) => {
    // Creating collection of currency column keys to performantly format rows when necessary
    if (column.type === PropertyType.Currency) currencyColumnKeys.push(column.cv_key);

    let type = getCollectionTableColumnType(column.type);

    return {
      def: column.cv_key,
      header: truncate(column.value),
      headerTooltip: getTooltip(column.value),
      numeric: column.type === PropertyType.Integer || column.type === PropertyType.Currency,
      type,
      value: getColumnValue(column.cv_key, type)
    };
  });

  let toLegalRow = (row: LegalRow): LegalRow => {
    // creating a copy of the row instead of mutating it to avoid side effects like breaking unit tests
    let transformedRow: LegalRow = { ...row };

    for (let key of currencyColumnKeys) {
      transformedRow[key] = formatCurrency(row[key] as string);
    }

    if (row['state_updated_at']) transformedRow['state_updated_at'] = parseDate(row['state_updated_at']);

    return transformedRow;
  };

  let dataSource = legalData?.rows.map(toLegalRow);

  return { columns, dataSource };
};

@Injectable({ providedIn: 'root' })
export class FundRepository {
  _fundStore$ = createStore({ name: 'holding' }, withProps<FundProps>(initialState));

  bankAccount$ = this._fundStore$.pipe(select(({ bankAccount }) => bankAccount));

  commentReviewFeatureConfiguration$ = this._fundStore$.pipe(
    select(({ featureConfigurationUserPermissions }) => featureConfigurationUserPermissions.comment_review)
  );

  questionnaireReviewFeatureConfiguration$ = this._fundStore$.pipe(
    select(
      ({ featureConfigurationUserPermissions }) => featureConfigurationUserPermissions.questionnaire_review
    )
  );

  commentReviewUserPermissions$ = this.commentReviewFeatureConfiguration$.pipe(
    select((commentReviewFeatureConfiguration) => commentReviewFeatureConfiguration?.user_permissions)
  );

  details$ = this._fundStore$.pipe(select(({ details }) => details));

  enableCommentReview$ = this.commentReviewFeatureConfiguration$.pipe(
    select(
      (commentReviewFeatureConfiguration) =>
        commentReviewFeatureConfiguration?.feature_configuration?.enabled || false
    )
  );

  enableQuestionnaireReview$ = this.questionnaireReviewFeatureConfiguration$.pipe(
    select(
      (questionnaireReviewFeatureConfiguration) =>
        questionnaireReviewFeatureConfiguration?.feature_configuration?.enabled || false
    )
  );

  questionnaireReviewUserPermissions$ = this.questionnaireReviewFeatureConfiguration$.pipe(
    select(
      (questionnaireReviewFeatureConfiguration) => questionnaireReviewFeatureConfiguration?.user_permissions
    )
  );

  questionnaireReviewActivityLog$ = this._fundStore$.pipe(
    select(({ investorQuestionnaireReviewActivityLog }) => investorQuestionnaireReviewActivityLog)
  );

  filteredLegalSectionSourceData$ = this._fundStore$.pipe(
    select((state) => {
      let { activeLegalStatusTags } = state;
      let legalSectionData = transformLegalSection(state);

      if (!activeLegalStatusTags?.length) return legalSectionData?.dataSource;

      return legalSectionData?.dataSource.filter((currentDataRow) =>
        activeLegalStatusTags?.includes(currentDataRow.signature_request_state)
      );
    })
  );

  fund$ = this._fundStore$.pipe(select(({ fund }) => fund));

  investorQuestionnaireReview$ = this._fundStore$.pipe(
    select(({ investorQuestionnaireReview }) => investorQuestionnaireReview)
  );

  investors$ = this._fundStore$.pipe(select(({ investors }) => investors));

  lastInvestorQuestionnaireReviewActivity$ = this.investorQuestionnaireReview$.pipe(
    select(
      (investorQuestionnaireReview) =>
        investorQuestionnaireReview?.activities[investorQuestionnaireReview?.activities.length - 1]
    )
  );

  // TODO Need to better understand the size of the legalData data set to better
  // understand if we should opt for performance or readability in this observable
  // (logic in .map has conflating concerns)
  legalSectionData$ = this._fundStore$.pipe(select(transformLegalSection));

  legalSections$ = this._fundStore$.pipe(
    select(({ legalSections }): LegalSection[] => {
      if (!legalSections || legalSections.length === 0) return;
      return legalSections;
    })
  );

  notifications$ = this._fundStore$.pipe(select(({ notifications }) => notifications));

  overviewInvestments$ = this._fundStore$.pipe(
    select(({ overviewInvestments }) => {
      if (!overviewInvestments) return;

      let fundOverviewCards: OverviewCard[];
      let prospectInvestorsOverviewCards: OverviewCard[];
      let investorsOverviewCards: OverviewCard[];

      // Note: Case when you don't have prospective investors
      if (overviewInvestments.length === 2) {
        [fundOverviewCards, investorsOverviewCards] = overviewInvestments;
      }

      // Note: Case when you have prospective investors
      if (overviewInvestments.length === 3) {
        [fundOverviewCards, prospectInvestorsOverviewCards, investorsOverviewCards] = overviewInvestments;
      }

      if (this.getFund()?.investors_locked) {
        investorsOverviewCards.unshift({
          class: 'circle-icon',
          icon: '/assets/icons/lock.svg',
          iconTooltip:
            'This fund has the investors locked to prevent accidental changes that may impact ownership percentage and capital call amounts. To unlock this fund, visit the Details tab.',
          label: null,
          value: null
        });
      }

      return [fundOverviewCards, prospectInvestorsOverviewCards, investorsOverviewCards];
    })
  );

  selectedLegalSection$ = this._fundStore$.pipe(select(({ selectedLegalSection }) => selectedLegalSection));

  summary$ = this._fundStore$.pipe(select(({ summary }) => summary));

  getFund(): Fund {
    return this._fundStore$.getValue().fund;
  }

  getFundDetails(): Details {
    return this._fundStore$.getValue().details;
  }

  getFundId(): ID {
    return this._fundStore$.getValue().fund?.id;
  }

  getInvestorQuestionnaireData(): InvestorQuestionnaireData[] {
    return this._fundStore$.getValue().investorQuestionnaireData;
  }

  getInvestorQuestionnaireReview(): InvestorQuestionnaireReview {
    return this._fundStore$.getValue().investorQuestionnaireReview;
  }

  getFeatureConfigurationUserPermissions(feature: FeatureConfigurationType): string[] {
    return this._fundStore$.getValue().featureConfigurationUserPermissions[feature]?.user_permissions;
  }

  getQuestionnaireReviewFeatureEnabled(): boolean {
    return !!this._fundStore$.getValue().featureConfigurationUserPermissions['questionnaire_review']
      ?.feature_configuration?.enabled;
  }

  getLastInvestorQuestionnaireReviewActivity(): QuestionnaireReviewActivity | undefined {
    let activities = this._fundStore$.getValue().investorQuestionnaireReview?.activities;
    return activities ? activities[activities.length - 1] : undefined;
  }

  getInvestors(): FundInvestor[] {
    return this._fundStore$.getValue().investors;
  }

  getLegalSections(): LegalSection[] {
    return this._fundStore$.getValue().legalSections;
  }

  getNotifications(): FundNotification[] {
    return this._fundStore$.getValue().notifications;
  }

  getSelectedLegalSection(): LegalSection {
    return this._fundStore$.getValue().selectedLegalSection;
  }

  reset(): void {
    this._fundStore$.reset();
  }

  setActiveLegalStatusTags(activeLegalStatusTags: LegalStatus[]): void {
    this._fundStore$.update(setProp('activeLegalStatusTags', activeLegalStatusTags));
  }

  setBankAccount(bankAccount: FundBankAccount): void {
    this._fundStore$.update(setProp('bankAccount', bankAccount));
  }

  setDetails(details: Details): void {
    this._fundStore$.update(setProp('details', details));
  }

  setFeatureConfigurationUserPermissions(
    featureConfigurationUserPermissions: FeatureConfigurationUserPermissions,
    type: string
  ): void {
    this._fundStore$.update((state) => {
      let existingFeatureConfigPermissions = state?.featureConfigurationUserPermissions;

      return {
        ...state,
        featureConfigurationUserPermissions: {
          ...existingFeatureConfigPermissions,
          [type]: featureConfigurationUserPermissions
        }
      };
    });
  }

  setFund(fund: Fund): void {
    this._fundStore$.update(setProp('fund', fund));
  }

  setFundInvestorFromInvestor(investor: Investor): void {
    this._fundStore$.update((state) => {
      let investors = state.investors?.map((fundInvestor: FundInvestor) => {
        if (investor.id !== fundInvestor.id) return fundInvestor;

        let {
          carry_percent_formatted,
          contributed_amount_formatted,
          management_fee_percent_formatted,
          total_due_formatted
        } = investor;

        let contributed_amount_value = parseInt(contributed_amount_formatted.replace(/\D/g, ''), 10);
        if (!contributed_amount_value) contributed_amount_formatted = undefined;

        return {
          ...fundInvestor,
          carry_percent_formatted,
          contributed_amount_formatted,
          management_fee_percent_formatted,
          total_due_formatted
        };
      });

      return { ...state, investors };
    });
  }

  setInvestorQuestionnaireData(investorQuestionnaireData: InvestorQuestionnaireData[]): void {
    this._fundStore$.update(setProp('investorQuestionnaireData', investorQuestionnaireData));
  }

  setInvestorQuestionnaireReview(investorQuestionnaireReview: InvestorQuestionnaireReview): void {
    this._fundStore$.update(setProp('investorQuestionnaireReview', investorQuestionnaireReview));
  }

  setinvestorQuestionnaireReviewActivityLog(
    investorQuestionnaireReviewActivityLog: InvestorQuestionnaireReviewActivityLog
  ): void {
    this._fundStore$.update(
      setProp('investorQuestionnaireReviewActivityLog', investorQuestionnaireReviewActivityLog)
    );
  }

  setInvestors(investors: FundInvestor[]): void {
    this._fundStore$.update(setProp('investors', investors));
  }

  setLegalData(legalData: LegalData): void {
    this._fundStore$.update(setProp('legalData', legalData));
  }

  setLegalSections(legalSections: LegalSection[]): void {
    this._fundStore$.update(setProp('legalSections', legalSections));
  }

  setNotifications(notifications: FundNotification[]): void {
    this._fundStore$.update(setProp('notifications', notifications));
  }

  setOverviewInvestments(overviewInvestments: OverviewCard[][]): void {
    this._fundStore$.update(setProp('overviewInvestments', overviewInvestments));
  }

  setSelectedLegalSection(section: LegalSection): void {
    this._fundStore$.update(setProp('selectedLegalSection', section));
  }

  setSummary(summary: Summary): void {
    this._fundStore$.update(setProp('summary', summary));
  }

  updateInvestors(updatedInvestors: FundInvestor[]): void {
    this._fundStore$.update((state) => {
      let investors = [...state.investors];

      for (let investor of updatedInvestors) {
        let index = investors.findIndex((potentialInvestorToUpdate) =>
          potentialInvestorToUpdate.id === investor.id);
        if (index > -1) investors[index] = investor;
      }

      return { ...state, investors };
    });
  }
}
